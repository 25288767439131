import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { StatCard } from '../../components/Card';
import { CarrierFilter, CarrierFilterFromDeliveryService, DateFilter, DeliveryServiceFilter, GreenPhoxFilter, ListPageDriverFilter, ShipperFilter, ShipperGroupFilter, StatusFilter } from '../../components/EntityFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { StatusBadge } from '../../components/StatusBadge';
import { TableClearFilter, TableExportFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, getTZ, handleApiError, isOwner, toLocalDate, toLocalTime } from '../../helpers';
import axios from 'axios';

const exportShipmentData = /* GraphQL */ `
      mutation ExportShipmentData($input: AWSJSON!) {
        exportShipmentData(input: $input)
      }
    `;

const BillingReport = () => {
    const myUser = useSelector(state => state.slice.USER)
    const myUserRole = useSelector((state) => state.slice.USER.role);

    const [spinner, showSpinner] = useState(false);
    const [shipments, setShipments] = useState([])
    const [facets, setFacets] = useState({})
    const [tz, setTz] = useState("EST");
    const [keyword, setKeyword] = useState('')
    const [shipperGroup, setShipperGroup] = useState()
    const [shipper, setShipper] = useState()
    const [carrier, setCarrier] = useState()
    const [status, setStatus] = useState();
    const [deliveryServices, setDeliveryServices] = useState();
    const [deliveryService, setDeliveryService] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();
    const [driver, setDriver] = useState();
    const [resetDate, setRestDate] = useState(0);
    const [timeZone, setTimeZone] = useState("America/New_York");


    const [dateFilters, setDateFilters] = useState({
        fromDate: moment().tz(timeZone)?.startOf('day').unix(),
        toDate: moment().tz(timeZone)?.endOf('day').unix()
    });
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/billing-report",
        })
    }, [])

    useEffect(() => {
        const getTimeZone = async () => {
            const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
            if (timez) {
                setTimeZone(timez?.id);
                setTz(timez?.alias)
            }
            else {
                setTimeZone("America/New_York")
                setTz("EST")
            }
        };

        if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
    }, [shipperGroup?.value, shipper?.value, carrier?.value]);
    useEffect(() => {
        setPageNumber(0);
        getElasticShipment();
    }, [shipperGroup, shipper, carrier, status, deliveryServices, deliveryService, driver]);

    useEffect(() => {
        const delay = setTimeout(() => { setPageNumber(0); getElasticShipment(); }, DELAY_TIMEOUT)
        return () => clearTimeout(delay)
    }, [keyword, dateFilters])

    useEffect(() => {
        getElasticShipment();
    }, [pageNumber]);

    useEffect(() => {
        setDriver(null)
        if (!shipperGroup || !shipper)
            setTimeZone("America/New_York");
        setTz("EST");
    }, [carrier, shipper, shipperGroup])

    const getElasticShipment = async () => {
        showSpinner(true);
       
        try {
            const apiName = 'api';
            const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
            let init = {
                body: {
                    aggs: {
                        status: {
                            terms: {
                                "field": "status.keyword",
                                "size": 100
                            }
                        }
                    },
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        },
                        {
                            "actualDeliveryTime": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            must: [],
                            must_not: []
                        }
                    }
                }
            };

            if (!keyword) {
                init.body.query.bool.must.push({
                    range: {
                        "expectedDeliveryTime": {
                            "gte": dateFilters?.fromDate,
                            "lte": dateFilters?.toDate
                        }
                    }
                })
            }

            if (keyword) {
                let fields = [
                    "shipTo.name^3",
                    "shipTo.first_name^2",
                    "shipTo.last_name",
                    "shipTo.phone",
                    "customer.extId",
                    "extId",
                    "extTrackingNumber",
                    "shipTo.address.address1",
                    "shipTo.address.postalCode",
                    "items.number.keyword"
                ]
                if (+keyword) {
                    fields.push("number")
                }
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword,
                            "fields": fields
                        }
                    }
                )
            }

            if (shipper) init.body.query.bool.must.push({ match: { shipperId: shipper?.value } })
            if (shipperGroup) init.body.query.bool.must.push({ match: { shipperGroupId: shipperGroup?.value } })
            if (carrier) init.body.query.bool.must.push({ match: { carrierId: carrier?.value } })
            if (driver) {
                if (driver?.value === "driverNotAssigned") {
                    init.body.query.bool.must_not.push({ exists: { field: "driver.name" } })
                } else { init.body.query.bool.must.push({ match: { driverId: driver?.value } }) }
            }
            if (deliveryService) init.body.query.bool.must.push({ match: { deliveryServiceId: deliveryService?.value } })
            if (status) init.body.query.bool.must.push({ match: { status: status?.value } });

            const data = await API.post(apiName, path, init);

            let statusCount = {};
            data.aggregations.status.buckets?.forEach((item) => {
                statusCount[item.key] = item.doc_count
            })

            setFacets(statusCount)
            setShipments(data?.hits?.hits);
            setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
            showSpinner(false);

        } catch (error) {
            handleApiError(error)
            showSpinner(false);

        }
    }

    const downloadReport = async () => {
        let downloadingToastId;
        try {
            downloadingToastId = toast.loading("Downloading...");

            const apiName = 'api';
            const path = "/report/hq/billing";
            const init = {
                body: {
                    expectedDeliveryTime: {
                        fromDate: dateFilters?.fromDate ?? null,
                        toDate: dateFilters?.toDate ?? null
                    },
                    shipperGroupId: shipperGroup?.value || null,
                    shipperId: shipper?.value ?? null,
                    carrierId: carrier?.value ?? null,
                    status: status?.value || null,
                    deliveryServiceId: deliveryService?.value || null,
                    driverId: driver?.value ?? null,
                    timezone: timeZone
                }
            };

            const { url } = await API.post(apiName, path, init);
            if (url) {
                const linkTag = document.createElement('a');
                linkTag.href = url;
                linkTag.click();
                linkTag.remove();
                toast.remove(downloadingToastId);
                toast.success("Download finished");
            } else return toast.error("No data found !")

        } catch (error) {
            console.error(error);
            toast.error("Something went wrong while exporting shipments billing.");
        } finally { toast.dismiss(downloadingToastId) }
    };


    const clearFilters = () => {
        setDateFilters({
            fromDate: moment().tz('America/New_York')?.startOf('day').unix(),
            toDate: moment().tz('America/New_York')?.endOf('day').unix()
        });
        setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
        setShipperGroup(null);
        setShipper(null);
        setCarrier(null);
        setDriver(null);
        setDeliveryService(null);
        setStatus(null);
        setTimeZone("America/New_York");
        setTz("EST");
    }

    const tzHandle = (e) => {
        setTimeZone(e);
    };
    return (
        <>
            <PageHeader name='Billing' shipperLabel={shipperGroup?.label} >
                <Row>
                    <ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} />
                    <ShipperFilter value={shipper} onChange={setShipper} shipperGroup={shipperGroup} />
                    {shipper?.value && <CarrierFilterFromDeliveryService value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
                    {!shipper?.value && < CarrierFilter value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
                    {carrier?.value && <ListPageDriverFilter value={driver} onChange={setDriver} carrier={carrier} shipperGroup={shipperGroup} shipper={shipper} />}
                    {(shipper?.value && carrier?.value) && <DeliveryServiceFilter value={deliveryService} myShipper={shipper} carrier={carrier} onChange={setDeliveryService} />}
                    <StatusFilter value={status} onChange={setStatus} />
                    <DateFilter key={resetDate} dateFilters={dateFilters} timeZone={timeZone} onChange={setDateFilters} isBilling={true} />
                    <TimeZoneFilter
                        title={"Time Zone"}
                        setTimeZone={setTimeZone}
                        dark={true}
                        onChange={tzHandle}
                        tz={tz}
                        setTz={setTz}
                    />
                    <TableClearFilter onClick={clearFilters} styleName={"mt-4"} />
                </Row>
            </PageHeader>


            <Container fluid>
                <Row>
                    <StatCard title='Ready for Pickup / Open' value={(facets?.READY_FOR_PICKUP || 0) + (facets?.OPEN || 0)} icon='box' />
                    <StatCard title='Out for Delivery' value={(facets?.OUT_FOR_DELIVERY || 0) + (facets?.IN_TRANSIT || 0)} icon='truck' />
                    <StatCard title='Delivered' value={facets?.DELIVERED || 0} icon='check-circle' />
                    <StatCard title='Exceptions / Returns' value={(facets?.EXCEPTION || 0) + (facets?.OUT_FOR_RETURN || 0) + (facets?.RETURNED || 0)} icon='alert-triangle' />
                </Row>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            {isOwner(myUserRole) &&
                                <TableExportFilter option={"Download Billing Report"} onChange={downloadReport} />
                            }
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>
                        <Table responsive size='sm' className='mb-0' >
                            <thead>
                                <tr>
                                    <th>Shipment #</th>
                                    <th>Shipper</th>
                                    <th>Driver / Courier</th>
                                    <th>Delivery Service</th>
                                    <th className='text-center'>Delivered At</th>
                                    <th className='text-center'>Total Shipper Billing</th>
                                    <th className='text-center'>Total Courier Billing </th>
                                    <th className='text-center'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    shipments?.length > 0 && shipments?.map((shipment) => {
                                        return (
                                            <tr key={shipment._source.id} style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    if (e.metaKey || e.ctrlKey) {
                                                        window.open(`/shipment/${shipment._source?.id}`, '_blank');
                                                    } else {
                                                        window.open(`/shipment/${shipment._source?.id}`, '_self');
                                                    }
                                                }}>
                                                <td>
                                                    <div>
                                                        {shipment?._source.isGreenPhox ? <img src="img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'20px'} width={'20px'} /> : <small className="ms-5"> </small>}
                                                        {shipment._source.number}
                                                    </div>
                                                    {shipment._source.batch_number > 0 && (<div className='small text-muted'>Batch #{shipment?._source.batch_number}</div>)}
                                                </td>
                                                <td>
                                                    {shipment._source.shipperGroup?.name}
                                                    <div className='small text-muted'>{shipment._source.shipFrom?.name}</div>
                                                </td>
                                                <td>
                                                    <div>{shipment._source.driver?.name || 'Unassigned'}</div>
                                                    <div className='text-small text-muted'>{shipment._source.carrier?.alias || shipment._source.carrier?.name}</div>
                                                </td>
                                                <td>
                                                    {shipment._source.deliveryService?.name}
                                                </td>
                                                <td className='text-center'>
                                                    {
                                                        shipment._source.actualDeliveryTime ? <>
                                                            {toLocalTime(shipment?._source.actualDeliveryTime, shipment?._source.shipFrom?.timezone?.id)}
                                                            <div className='small text-muted'>{toLocalDate(shipment?._source.actualDeliveryTime, shipment?._source.shipFrom?.timezone?.id)}</div>
                                                        </> : '-'
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    <div>{shipment._source?.billing_shipper ? "$" + Number(JSON.parse(shipment?._source?.billing_shipper)?.total).toFixed(2) : "-"} </div>
                                                </td>
                                                <td className='text-center'>
                                                    <div>{shipment._source?.billing_carrier ? "$" + Number(JSON.parse(shipment?._source?.billing_carrier)?.total).toFixed(2) : "-"}</div>
                                                </td>

                                                <td className='text-center'>
                                                    <StatusBadge status={shipment._source.status} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>

                            <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={8} />
                        </Table>
                    </Spinner>
                </Card>
            </Container >
        </>
    )
}

export default BillingReport
