/* eslint-disable react-hooks/exhaustive-deps */

import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "../../components/Card";
import {
  CarrierFilter,
  CarrierFilterFromDeliveryService,
  DateFilter,
  ShipperFilter,
  ShipperGroupFilter,
} from "../../components/EntityFilter";
import HeatMap from "../../components/HeatMap";
import Spinner from "../../components/Spinner";
import { getTZ, handleApiError, PAGE_SIZE, PAGE_TITLE } from "../../helpers";
import {
  DoughnutChart,
  LineChart,
  StackedBarChart,
} from "./../../components/Chart";
import ReactGA from "react-ga4";
import { TableClearFilter, TimeZoneFilter } from "../../components/TableFilter";
import { useSelector } from "react-redux";

const Dashboard = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/dashboard",
    });
    document.title = `Dashboard ${PAGE_TITLE}`;
  }, []);

  let pageSize = 1000;

  const apiName = "api";

  const [spinner, showSpinner] = useState(false);

  const [shipperGroup, setShipperGroup] = useState();
  const [shipper, setShipper] = useState();
  const [carrier, setCarrier] = useState();
  const [tz, setTz] = useState("EST");
  const [shipperGroupData, setShipperGroupData] = useState(null);
  const [deliveryServiceData, setDeliveryServiceData] = useState(null);
  const [carrierData, setCarrierData] = useState(null);
  const [ratingData, setRatingData] = useState(null);
  const [npsData, setNpsData] = useState(null);
  const [shipmentData, setShipmentData] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [resetDate, setRestDate] = useState(0);
  const [timeZone, setTimeZone] = useState("America/New_York");

  const [dateFilters, setDateFilters] = useState({
    fromDate: moment().tz(timeZone)?.subtract(6, "days").startOf("day").unix(),
    toDate: moment().tz(timeZone)?.endOf("day").unix(),
  });

  useEffect(() => {
    getData();
  }, [dateFilters, shipperGroup, shipper, carrier]);
  useEffect(() => {
    const getTimeZone = async () => {
      const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
      if (timez) {
        setTimeZone(timez?.id)
        setTz(timez?.alias)
      }
      else {
        setTimeZone("America/New_York")
        setTz("EST")
      }
    };
    if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
    if (!shipperGroup || !shipper || !carrier) {
      setTimeZone("America/New_York");
      setTz("EST");
    }
  }, [shipperGroup?.value, shipper?.value, carrier?.value]);

  async function getRatings() {
    try {
      const apiName = "api";
      const path = `/search/shipment-rating?size=${0}&from=${0}`;
      let init = {
        body: {
          query: {
            bool: {
              must: [
                {
                  range: {
                    createdTime: {
                      gte: dateFilters?.fromDate,
                      lte: dateFilters?.toDate,
                    },
                  },
                },
              ],
            },
          },
        },
      };

      if (shipper)
        init.body.query.bool.must.push({
          match: { shipperId: shipper?.value },
        });
      if (shipperGroup)
        init.body.query.bool.must.push({
          match: { shipperGroupId: shipperGroup?.value },
        });
      if (carrier)
        init.body.query.bool.must.push({
          match: { carrierId: carrier?.value },
        });

      let data = await API.post(apiName, path, init);
      let page = 0;
      let apiArgs = [];
      while (page < Math.ceil(data.hits.total.value / pageSize)) {
        const path = `/search/shipment-rating?size=${pageSize}&from=${page * pageSize}`;
        apiArgs.push({ apiName, path, init });
        page++;
      }
      let allAPIs = apiArgs.map((call) =>
        API.post(call.apiName, call.path, call.init)
      );

      Promise.all(allAPIs)
        .then((results) => {
          let ratings = [];
          for (let items of results) {
            const sourceData = items?.hits?.hits?.map((item) => item?._source);
            ratings.push(...sourceData);
          }
          const ratingData = {};
          const npsData = {};
          ratings.forEach((rating) => {
            const createdTime =
              rating?.createdTime &&
              moment.unix(rating?.createdTime).format("YYYY-MM-DD");
            const ratingsByDate = ratings.filter(
              (x) =>
                moment.unix(x?.createdTime).format("YYYY-MM-DD") === createdTime
            );
            let totalRating = 0;

            let nps = 0;
            let promoters = 0;
            let detractors = 0;

            ratingsByDate.forEach((item) => {
              totalRating += item.rating || 0;

              if (item.nps && item.nps >= 1) nps += 1;
              if (item.nps && item.nps >= 9) promoters += 1;
              if (item.nps && item.nps <= 6) detractors += 1;
            });

            const averageRating = totalRating / ratingsByDate.length;
            ratingData[createdTime] = averageRating;
            npsData[createdTime] = parseInt(
              ((promoters - detractors) * 100) / nps
            );
          });

          const ratingByDate = Object.entries(ratingData)
            .map(([createdTime, count]) => ({
              label: createdTime,
              count: count.toFixed(2),
            }))
            .sort((a, b) => new Date(a.label) - new Date(b.label));

          const npsByDate = Object.entries(npsData)
            .map(([createdTime, count]) => ({
              label: createdTime,
              count: count.toFixed(2),
            }))
            .sort((a, b) => new Date(a.label) - new Date(b.label));

          const ratingLabels = ratingByDate.map(({ label }) =>
            moment(label).format("MMM DD")
          );
          const ratingDataset = ratingByDate.map(({ count }) => count);
          const ratingChart = { datasets: ratingDataset, labels: ratingLabels };
          setRatingData(ratingChart);

          const npsLabels = npsByDate.map(({ label }) =>
            moment(label).format("MMM DD")
          );
          const npsDataset = npsByDate.map(({ count }) => count);
          const npsChart = { datasets: npsDataset, labels: npsLabels };
          setNpsData(npsChart);
          showSpinner(false);
        })
        .catch((error) => {
          handleApiError(error)
        });
    } catch (error) {
      handleApiError(error)
    }
  }

  async function getHeatMap() {
    try {
      const apiName = "api";
      const path = `/search/shipment?size=${0}&from=${0}`;
      let init = {
        body: {
          query: {
            bool: {
              must: [
                {
                  range: {
                    actualDeliveryTime: {
                      gte: dateFilters?.fromDate,
                      lte: dateFilters?.toDate,
                    },
                  },
                },
                {
                  match: { status: "DELIVERED" },
                },
              ],
            },
          },
        },
      };

      if (shipper)
        init.body.query.bool.must.push({
          match: { shipperId: shipper?.value },
        });
      if (shipperGroup)
        init.body.query.bool.must.push({
          match: { shipperGroupId: shipperGroup?.value },
        });
      if (carrier)
        init.body.query.bool.must.push({
          match: { carrierId: carrier?.value },
        });

      let data = await API.post(apiName, path, init);
      let page = 0;
      let apiArgs = [];
      while (page < Math.ceil(data.hits.total.value / pageSize)) {
        const path = `/search/shipment?size=${pageSize}&from=${page * pageSize}`;
        apiArgs.push({ apiName, path, init });
        page++;
      }
      let x = apiArgs.map((call) =>
        API.post(call.apiName, call.path, call.init)
      );
      Promise.all(x).then((res) => {
        let shipments = [];
        for (let items of res) {
          const sourceData = items?.hits?.hits?.map((item) => item?._source);
          shipments.push(...sourceData);
        }
        let locations = [];
        shipments.forEach((shipment) => {
          if (shipment.shipTo?.address?.location)
            locations.push(shipment.shipTo?.address?.location);
        });
        setLocationData(locations);
      });
    } catch (error) {
      handleApiError(error)
    }
  }

  const getData = async () => {
    try {
      showSpinner(true);
      const params = {
        fromDate: dateFilters.fromDate,
        toDate: dateFilters.toDate,
        shipperGroupId: null,
        shipperId: null,
        carrierId: null,
        status: "DELIVERED",
      };

      if (shipperGroup) params.shipperGroupId = shipperGroup.value;
      if (shipper) params.shipperId = shipper.value;
      if (carrier) params.carrierId = carrier.value;

      const apiName = "api";
      const path = `/search/shipment?size=${0}`;

      let init = {
        body: {
          size: 0,
          aggs: {
            shipments_over_time: {
              date_histogram: {
                field: "expectedDeliveryDateTime",
                fixed_interval: "1d",
                min_doc_count: 1,
              },
            },
            status: {
              terms: {
                field: "status.keyword",
                size: 1000,
              },
            },
            shipper_group: {
              terms: {
                field: "shipperGroup.name.keyword",
                size: 1000,
              },
            },
            shipper: {
              terms: {
                field: "shipper.name.keyword",
                size: 1000,
              },
            },
            carrier: {
              terms: {
                field: "carrier.name.keyword",
                size: 1000,
              },
            },
            driver: {
              terms: {
                field: "driver.name.keyword",
                size: 1000,
              },
            },
            delivery_service: {
              terms: {
                field: "deliveryService.name.keyword",
                size: 1000,
              },
            },
          },
          query: {
            bool: {
              must: [
                {
                  range: {
                    expectedDeliveryTime: {
                      gte: dateFilters?.fromDate,
                      lte: dateFilters?.toDate,
                    },
                  },
                },
                {
                  match: { status: "DELIVERED" },
                },
              ],
            },
          },
        },
      };

      if (shipper)
        init.body.query.bool.must.push({ match: { shipperId: shipper?.value } });
      if (shipperGroup)
        init.body.query.bool.must.push({
          match: { shipperGroupId: shipperGroup?.value },
        });
      if (carrier)
        init.body.query.bool.must.push({ match: { carrierId: carrier?.value } });

      const data = await API.post(apiName, path, init);
      const aggs = data.aggregations;

      setShipmentData({
        labels: aggs.shipments_over_time.buckets.map((item) =>
          moment.unix(item.key / 1000).format("MMM-DD")
        ),
        datasets: aggs.shipments_over_time.buckets.map((item) => item.doc_count),
      });
      setShipperGroupData({
        labels: aggs.shipper_group.buckets.map((item) => item.key),
        datasets: aggs.shipper_group.buckets.map((item) => item.doc_count),
      });
      setCarrierData({
        labels: aggs.carrier.buckets.map((item) => item.key),
        datasets: aggs.carrier.buckets.map((item) => item.doc_count),
      });
      setDeliveryServiceData({
        labels: aggs.delivery_service.buckets.map((item) => item.key),
        datasets: aggs.delivery_service.buckets.map((item) => item.doc_count),
      });

      Promise.all([getRatings(), getHeatMap(params)]).then(() =>
        showSpinner(false)
      );

    } catch (error) {
      handleApiError(error);
    }
  };
  const clearFilters = () => {
    setDateFilters({
      fromDate: moment()
        .tz("America/New_York")
        ?.subtract(6, "days")
        .startOf("day")
        .unix(),
      toDate: moment().tz("America/New_York")?.endOf("day").unix(),
    });
    setRestDate((prev) => (prev === 0 ? (prev = 1) : (prev = 0)));
    setShipperGroup(null);
    setShipper(null);
    setCarrier(null);
    setTimeZone("America/New_York");
    setTz("EST");
  };
  const tzHandle = (e) => {
    setTimeZone(e);
  };

  return (
    <>
      <div className="header bg-dark pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">
                  {shipper?.label || shipperGroup?.label || `Phox Health`}
                </h6>
                <h1 className="header-title text-white">Dashboard</h1>
              </Col>
              <ShipperGroupFilter
                value={shipperGroup}
                onChange={setShipperGroup}
                dark={true}
              />
              <ShipperFilter
                value={shipper}
                onChange={setShipper}
                shipperGroup={shipperGroup}
                dark={true}
              />
              {shipper?.value && (
                <CarrierFilterFromDeliveryService
                  value={carrier}
                  onChange={setCarrier}
                  dark={true}
                  shipper={shipper}
                  shipperGroup={shipperGroup}
                />
              )}
              {!shipper?.value && (
                <CarrierFilter
                  value={carrier}
                  onChange={setCarrier}
                  dark={true}
                  shipper={shipper}
                  shipperGroup={shipperGroup}
                />
              )}
              <DateFilter
                key={resetDate}
                onChange={setDateFilters}
                startOf={6}
                timeZone={timeZone}
                dateFilters={dateFilters}
              // dark={true}
              />
              <TimeZoneFilter
                title={"Time Zone"}
                setTimeZone={setTimeZone}
                dark={false}
                onChange={tzHandle}
                tz={tz}
                setTz={setTz}
              />
              <TableClearFilter onClick={clearFilters} styleName={"mt-4"} />
            </Row>
          </div>
          <div className="header-footer">
            <Spinner display={spinner}>
              <StackedBarChart data={shipmentData} dark={true} />
            </Spinner>
          </div>
        </Container>
      </div>

      <Container fluid className="mt-n6">
        <Row>
          <Card title="Deliveries by Shipper" size="col-xl-4">
            <Spinner display={spinner}>
              <DoughnutChart data={shipperGroupData} />
            </Spinner>
          </Card>
          <Card title="Deliveries by Courier" size="col-xl-4">
            <Spinner display={spinner}>
              <DoughnutChart data={carrierData} />
            </Spinner>
          </Card>
          <Card title="Deliveries by Delivery Service" size="col-xl-4">
            <Spinner display={spinner}>
              <DoughnutChart data={deliveryServiceData} />
            </Spinner>
          </Card>
        </Row>

        <Card title="Heatmap" bodyPadding="p-0">
          <Spinner display={spinner}>
            <HeatMap data={locationData} />
          </Spinner>
        </Card>

        <Row>
          <Card title="Patient Rating" size="col-xl-6">
            <Spinner display={spinner}>
              <LineChart data={ratingData} min="1" max="5" />
            </Spinner>
          </Card>
          <Card title="Net Promoter Score" size="col-xl-6">
            <Spinner display={spinner}>
              <LineChart data={npsData} min="-100" max="100" />
            </Spinner>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
