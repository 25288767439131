/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { StatCard } from '../../components/Card';
import { CarrierFilter, CarrierFilterFromDeliveryService, DateFilter, NPSFilter, RatingFilter, ShipperFilter, ShipperGroupFilter } from '../../components/EntityFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableClearFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, getTZ, handleApiError, toLocalDate, toLocalTime } from '../../helpers';
import { DoughnutChart } from '../../components/Chart';
import CardHeader from 'react-bootstrap/esm/CardHeader';

const RatingDashboard = () => {

	const navigate = useNavigate();

	let intialNpsRating = [
		{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
		{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
		{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
	]
	const [spinner, showSpinner] = useState(false);
	const [ratings, setRatings] = useState([])
	const [resetDate, setRestDate] = useState(0);
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [ratingAverage, setRatingAverage] = useState(0)
	const [responseAverage, setResponseAverage] = useState(0)
	const [npsAverage, setNpsAverage] = useState(0)
	const [facets, setFacets] = useState({})
	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [timeZone, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");
	const [starRating, setStarRating] = useState([]);
	const [totalStarRatingCount, setTotalStarRatingCount] = useState(0);
	const [npsRating, setNpsRating] = useState(intialNpsRating);
	const [ratingFilter, setRatingFilter] = useState();
	const [npsFilter, setNpsFilter] = useState();
	const [elasticSort, setElasticSort] = useState({ field: "ratings.items.createdAt", order: "desc" });
	const [clientData, setClientData] = useState();
	const [locationData, setLocationData] = useState();
	const [courierData, setCourierData] = useState();

	const sortDirections = useRef({});

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZone)?.subtract(6, "days").startOf("day").unix(),
		toDate: moment().tz(timeZone)?.endOf("day").unix(),
	});


	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/star-phox",
		})
	}, [])
	useEffect(() => {
		const getTimeZone = async () => {
			const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
			if (timez) {
				setTimeZone(timez?.id);
				setTz(timez?.alias);
			} else {
				setTimeZone("America/New_York")
				setTz("EST")
			}
		};
		if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
		if (!shipperGroup || !shipper || !carrier) {
			setTimeZone("America/New_York");
			setTz("EST");
		}
	}, [shipperGroup?.value, shipper?.value, carrier?.value]);

	useEffect(() => {
		const delay = setTimeout(() => { setPageNumber(0); getElasticRatings(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, shipperGroup, shipper, carrier, ratingFilter, npsFilter, elasticSort])

	useEffect(() => {
		getElasticRatings();
	}, [pageNumber]);

	function caluclateAverageResponseRate(totalShipments, totalNPSCount, totalRatingCount) {
		let avg_response_rate;
		if (totalShipments != null && totalRatingCount > totalNPSCount) {
			avg_response_rate = (totalRatingCount / totalShipments) * 100;
		}
		else if (totalNPSCount != null) {
			avg_response_rate = (totalNPSCount / totalShipments) * 100;
		} else {
			avg_response_rate = 0
		}
		return avg_response_rate?.toFixed(1)
	}

	const getElasticRatings = async () => {
		showSpinner(true)

		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					"aggs": {
						"shipper_group": {
							"terms": {
								"field": "shipperGroupId.keyword",
								"size": 100
							},
							"aggs": {
								"total_shipments_count": {
									"value_count": {
										"field": "id.keyword"
									}
								}
							}
						},
						"location": {
							"terms": {
								"field": "shipperId.keyword",
								"size": 100
							},
							"aggs": {
								"total_shipments_count": {
									"value_count": {
										"field": "id.keyword"
									}
								}
							}
						},
					},
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								}
							]
						}
					}
				}
			}

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipper) init.body.query.bool.must.push({ match: { "shipperId": shipper?.value } })
			if (shipperGroup) init.body.query.bool.must.push({ match: { "shipperGroupId": shipperGroup?.value } })
			if (carrier) init.body.query.bool.must.push({ match: { "carrierId": carrier?.value } })
			if (ratingFilter) init.body.query.bool.must.push({ term: { "ratings.items.rating": ratingFilter.value } })

			if (npsFilter) {
				if (npsFilter.value === "promoters") {
					init.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 9, "lte": 10 } } })
				} else if (npsFilter.value === "passives") {
					init.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 7, "lte": 8 } } })
				} else if (npsFilter.value === "detractors") {
					init.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 1, "lte": 6 } } })
				}
			}

			let feedbackCountData = await API.post(apiName, path, init);
			let totalShipmentsCount = feedbackCountData?.hits?.total?.value;

			const shipmentInit = {
				body: {
					"aggs": {
						"nps_stats": {
							"scripted_metric": {
								"init_script": "state.promoters = 0; state.passives = 0; state.detractors = 0; state.total = 0;",
								"map_script": `
									if (doc.containsKey('ratings.items.nps') && doc['ratings.items.nps'].size() > 0) {
										def nps = doc['ratings.items.nps'].value;
										state.total += 1;
										
										if (nps > 8) {
										state.promoters += 1;
										} else if (nps >= 7 && nps <= 8) {
										state.passives += 1;
										} else {
										state.detractors += 1;
										}
									}
								`,
								"combine_script": "return state;",
								"reduce_script": `
								int promoters = 0;
								int passives = 0;
								int detractors = 0;
								int total = 0;

								for (s in states) {
									promoters += s.promoters;
									passives += s.passives;
									detractors += s.detractors;
									total += s.total;
								}

								if (total > 0) {
									double promotersPercentage = (promoters / (double) total) * 100;
									double passivesPercentage = (passives / (double) total) * 100;
									double detractorsPercentage = (detractors / (double) total) * 100;

									double npsScore = Math.round((Math.round(promotersPercentage)) - (Math.round(detractorsPercentage)));

									return [
									"promoters": promoters,
									"promotersPercentage": promotersPercentage,
									"passives": passives,
									"passivesPercentage": passivesPercentage,
									"detractors": detractors,
									"detractorsPercentage": detractorsPercentage,
									"npsScore": npsScore,
									"total": total
									];
								} else {
									return [
									"promoters": promoters,
									"promotersPercentage": 0,
									"passives": passives,
									"passivesPercentage": 0,
									"detractors": detractors,
									"detractorsPercentage": 0,
									"npsScore": 0,
									"total": total
									];
								}
								`
							}
						},
						"avg_rating": {
							"avg": {
								"field": "ratings.items.rating"
							}
						},
						"total_ratings_count": {
							"value_count": {
								"field": "ratings.items.rating"
							}
						},
						"ratings_by_star": {
							"terms": {
								"field": "ratings.items.rating",
								"size": 5,
								"order": { "_key": "desc" },
								"min_doc_count": 0
							},
							"aggs": {
								"rating_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								}

							}

						},
						"shipper_group": {
							"terms": {
								"field": "shipperGroupId.keyword",
								// "order": {
								// 	"client_name": "asc"
								// },
								"size": 100
							},
							"aggs": {
								"client_name": {
									"top_hits": {
										"_source": ["shipperGroup.name"],
										"size": 1,
										"sort": [
											{
												"createdTime": {
													"order": "desc"
												}
											}
										]
									}
								},
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"avg_nps": {
									"scripted_metric": {
										"init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
										"map_script": `
												if (doc['ratings.items.nps'].size() > 0) {
													def nps = doc['ratings.items.nps'].value;
													if (nps >= 9) {
														state.promoters += 1;
													} else if (nps <= 6) {
														state.detractors += 1;
													}
													state.total += 1;
												}
											`,
										"combine_script": "return state;",
										"reduce_script": `
											int promoters = 0;
											int detractors = 0;
											int total = 0;
											
											for (s in states) {
												promoters += s.promoters;
												detractors += s.detractors;
												total += s.total;
											}
											
											double promotersPercentage = 0.0;
											double detractorsPercentage = 0.0;
											double result = 0.0;
											
											if (total > 0) {
												promotersPercentage = (promoters / (double) total) * 100;
												detractorsPercentage = (detractors / (double) total) * 100;
												result = Math.round(promotersPercentage) - Math.round(detractorsPercentage);
											}
											
											return (int) Math.round(result)
										`
									}
								},
								"total_ratings_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								},
								"total_shipments_count": {
									"value_count": {
										"field": "shipperGroup.name.keyword"
									}
								},
								"total_nps_count": {
									"value_count": {
										"field": "ratings.items.nps"
									}
								},
							}
						},
						"location": {
							"terms": {
								"field": "shipperId.keyword",
								"order": { "avg_rating": "desc" },
								"size": 100
							},
							"aggs": {
								"shipper_name": {
									"top_hits": {
										"_source": ["shipper.name"],
										"size": 1,
										"sort": [
											{
												"createdTime": {
													"order": "desc"
												}
											}
										]
									}
								},
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"avg_nps": {
									"scripted_metric": {
										"init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
										"map_script": `
												if (doc['ratings.items.nps'].size() > 0) {
													def nps = doc['ratings.items.nps'].value;
													if (nps >= 9) {
														state.promoters += 1;
													} else if (nps <= 6) {
														state.detractors += 1;
													}
													state.total += 1;
												}
											`,
										"combine_script": "return state;",
										"reduce_script": `
											int promoters = 0;
											int detractors = 0;
											int total = 0;
											
											for (s in states) {
												promoters += s.promoters;
												detractors += s.detractors;
												total += s.total;
											}
											
											double promotersPercentage = 0.0;
											double detractorsPercentage = 0.0;
											double result = 0.0;
											
											if (total > 0) {
												promotersPercentage = (promoters / (double) total) * 100;
												detractorsPercentage = (detractors / (double) total) * 100;
												result = Math.round(promotersPercentage) - Math.round(detractorsPercentage);
											}
											
											return (int) Math.round(result)
										`
									}
								},
								"total_ratings_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								},
								"total_shipments_count": {
									"value_count": {
										"field": "shipperGroup.name.keyword"
									}
								},
								"total_nps_count": {
									"value_count": {
										"field": "ratings.items.nps"
									}
								},
							}
						},
						"carrier": {
							"terms": {
								"field": "carrier.name.keyword",
								"order": {
									"avg_rating": "desc"
								}
							},
							"aggs": {
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"rating_filter": {
									"bucket_selector": {
										"buckets_path": {
											"avgRating": "avg_rating.value"
										},
										"script": "params.avgRating > 0"
									}
								}
							}
						}
					},


					query: {
						bool: {
							must: [
								{
									"match": { status: "DELIVERED" }
								},
								{
									"exists": {
										"field": "ratings.items.feedback"
									}
								}
							]
						}
					}
				}

			}

			if (!keyword) {
				shipmentInit.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				shipmentInit.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipperGroup) shipmentInit.body.query.bool.must.push({ match: { "shipperGroupId": shipperGroup?.value } })
			if (shipper) shipmentInit.body.query.bool.must.push({ match: { "shipperId": shipper?.value } })
			if (carrier) shipmentInit.body.query.bool.must.push({ match: { "carrierId": carrier?.value } })
			if (ratingFilter) shipmentInit.body.query.bool.must.push({ term: { "ratings.items.rating": ratingFilter.value } })

			if (npsFilter) {
				if (npsFilter.value === "promoters") {
					shipmentInit.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 9, "lte": 10 } } })
				} else if (npsFilter.value === "passives") {
					shipmentInit.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 7, "lte": 8 } } })
				} else if (npsFilter.value === "detractors") {
					shipmentInit.body.query.bool.must.push({ "range": { "ratings.items.nps": { "gte": 1, "lte": 6 } } })
				}

			}

			if (elasticSort?.field) {
				shipmentInit.body.sort = [
					{
						[elasticSort?.field]: {
							"order": elasticSort?.order,
							"unmapped_type": "date"
						}
					}
				]
			}


			let data = await API.post(apiName, `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`, shipmentInit);

			setRatings(data?.hits?.hits)
			setFacets(data?.aggregations)
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));

			if (data?.aggregations?.avg_rating?.value) setRatingAverage(data?.aggregations?.avg_rating?.value);
			else setRatingAverage(0);

			if (data?.aggregations?.ratings_by_star?.buckets?.length) {
				let totaRatingCount = data?.aggregations?.ratings_by_star?.buckets?.reduce((accumulator, item) => accumulator + item?.rating_count?.value, 0,);
				setTotalStarRatingCount(totaRatingCount)
				setStarRating(data?.aggregations?.ratings_by_star?.buckets);
			}
			else { setStarRating([]); setTotalStarRatingCount(0) }


			if (data?.aggregations?.nps_stats) {
				let nps_stats = data?.aggregations?.nps_stats?.value
				let avg_nps = nps_stats?.npsScore ? Math.round(nps_stats?.npsScore) : 0
				let ratings = [
					{ label: 'Promoters', color: '#00A76F', percentage: nps_stats?.promotersPercentage, count: nps_stats?.promoters },
					{ label: 'Passives', color: '#FEC203', percentage: nps_stats?.passivesPercentage, count: nps_stats?.passives },
					{ label: 'Detractors', color: '#E84521', percentage: nps_stats?.detractorsPercentage, count: nps_stats?.detractors }
				]
				setNpsRating(ratings)
				setNpsAverage(avg_nps)
			} else {
				setNpsAverage(0);
				setNpsRating(intialNpsRating);
			}

			if (totalShipmentsCount) {
				let avg_response_rate = caluclateAverageResponseRate(totalShipmentsCount, data?.aggregations?.nps_stats?.value?.total, data?.aggregations?.total_ratings_count?.value)
				setResponseAverage(avg_response_rate)
			} else setResponseAverage(0)

			if (data?.aggregations?.shipper_group?.buckets?.length && feedbackCountData?.aggregations?.shipper_group?.buckets?.length) {
				for (let item of data?.aggregations?.shipper_group?.buckets) {
					let total_shipments_count = 0;
					for (let hit of feedbackCountData?.aggregations?.shipper_group?.buckets) {
						if (item.key === hit.key) {
							total_shipments_count += hit?.total_shipments_count?.value
						}
					}
					item.total_shipments_count = total_shipments_count;
					let avg_response_rate = 0;
					if (total_shipments_count) {
						avg_response_rate = caluclateAverageResponseRate(total_shipments_count, item.total_nps_count?.value, item.total_ratings_count?.value)
					}
					item.avg_response_rate = avg_response_rate
				}
				setClientData(data?.aggregations?.shipper_group?.buckets)
			} else setClientData(null)

			if (shipperGroup && data?.aggregations?.location?.buckets?.length && feedbackCountData?.aggregations?.location?.buckets?.length) {
				for (let item of data?.aggregations?.location?.buckets) {
					let total_shipments_count = 0;
					for (let hit of feedbackCountData?.aggregations?.location?.buckets) {
						if (item.key === hit.key) {
							total_shipments_count += hit?.total_shipments_count?.value
						}
					}
					item.total_shipments_count = total_shipments_count;
					let avg_response_rate = 0;
					if (total_shipments_count) {
						avg_response_rate = caluclateAverageResponseRate(total_shipments_count, item.total_nps_count?.value, item.total_ratings_count?.value)
					}
					item.avg_response_rate = avg_response_rate
				}
				setLocationData(data?.aggregations?.location?.buckets)
			} else setLocationData(null)

			if (data?.aggregations?.carrier?.buckets?.length) {
				setCourierData(data?.aggregations?.carrier?.buckets)
			} else setCourierData(null)

			showSpinner(false);
		} catch (error) {
			showSpinner(false)
			handleApiError(error)
		}

	}

	const clearFilters = () => {
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setDateFilters({
			fromDate: moment().tz(timeZone)?.subtract(6, "days").startOf("day").unix(),
			toDate: moment().tz(timeZone)?.endOf("day").unix(),
		});
		setElasticSort({ field: "ratings.items.createdAt", order: "desc" });
		setNpsFilter(null)
		setRatingFilter(null)
		setShipperGroup(null);
		setShipper(null);
		setKeyword('');
		setCarrier(null)
		setPageNumber(0);
		setTimeZone("America/New_York");
		setFacets({})
		setTz("EST");
	};
	const tzHandle = (e) => {
		setTimeZone(e);
	};

	const calculatePercentage = (count) => (count === 0 || totalStarRatingCount == 0) ? 0 : (count / totalStarRatingCount) * 100;

	function formatStarRatingCount(count) {
		if (count > 1000) {
			return new Intl.NumberFormat('en-US', {
				notation: 'compact',
				maximumFractionDigits: 1
			}).format(count);
		}
		return count.toString();
	}

	function handleElasticSort(field) {
		if (field === elasticSort?.field) {
			if (elasticSort?.order === "desc") {
				setElasticSort((prev) => ({ ...prev, order: "asc" }))
			} else {
				setElasticSort((prev) => ({ ...prev, order: "desc" }))
			}
		} else {
			setElasticSort({ field, order: "desc" })
		}
	}

	const onSort = (field, data, set) => {
		const currentDirection = sortDirections.current[field] || "asc";
		const newDirection = currentDirection === "asc" ? "desc" : "asc";
		sortDirections.current[field] = newDirection;

		const getValue = (obj, path) =>
			path.replace(/\[(\d+)\]/g, '.$1')
				.split('.')
				.reduce((o, key) => (o ? o[key] : undefined), obj);

		if (data?.length) {
			const sortedData = [...data].sort((a, b) => {
				let aValue = getValue(a, field);
				let bValue = getValue(b, field);

				const aIsNumber = !isNaN(parseFloat(aValue)) && isFinite(aValue);
				const bIsNumber = !isNaN(parseFloat(bValue)) && isFinite(bValue);

				if (aIsNumber && bIsNumber) {
					aValue = parseFloat(aValue);
					bValue = parseFloat(bValue);
				} else {
					aValue = aValue?.toString() ?? '';
					bValue = bValue?.toString() ?? '';
				}

				if (aValue < bValue) {
					return newDirection === "asc" ? -1 : 1;
				}
				if (aValue > bValue) {
					return newDirection === "asc" ? 1 : -1;
				}
				return 0;
			});
			set(sortedData);
		}
	};


	return (
		<>
			<PageHeader name='StarPhox' shipperLabel={shipperGroup?.label}>
				<Row>
					<ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />
					<ShipperFilter value={shipper} onChange={setShipper} shipperGroup={shipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />
					{shipper && <CarrierFilterFromDeliveryService value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
					{!shipper && <CarrierFilter value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />}
					<RatingFilter value={ratingFilter} onChange={setRatingFilter} />
					<NPSFilter value={npsFilter} onChange={setNpsFilter} />
					<DateFilter key={resetDate} startOf={6} onChange={setDateFilters} timeZone={timeZone} dateFilters={dateFilters} hideRange={["Tomorrow"]} />

					<TimeZoneFilter
						title={"Time Zone"}
						setTimeZone={setTimeZone}
						dark={true}
						onChange={tzHandle}
						tz={tz}
						setTz={setTz}
					/>
					<TableClearFilter onClick={clearFilters} startOf={6} styleName={"mt-4"} />
				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Average Rating' value={ratingAverage ? ratingAverage?.toFixed(1) : '-'} icon='star' />
					<StatCard title='NPS' value={npsAverage ?? '-'} icon='thumbs-up' />
					<StatCard title='Response Rate' value={responseAverage ?? 0} icon='percent' />
				</Row>

				<Card className='p-3'>
					<Spinner display={spinner}>
						<Row md={1} lg={2} className='align-items-center' >
							<Col className='px-4 border-end' >
								<div className="rating-summary">
									<div className="d-flex align-items-center mb-3">
										<div className='mb-2 me-2'>
											<StarRatings rating={ratingAverage || 0} starRatedColor='#FFCD3C' starDimension='18px' starSpacing='1px' />
										</div>
										<span className='text-muted'>({formatStarRatingCount(totalStarRatingCount)} Reviews )</span>
									</div>
									{starRating?.map((item) => (
										<div key={item?.key} className="d-flex align-items-center justify-content-between mb-3">
											<span className="me-2">{item?.key} <span className="text-warning">&#9733;</span></span>
											<div className="progress flex-grow-1 me-2" style={{ height: '10px' }}>
												<div
													className="progress-bar bg-warning"
													role="progressbar"
													style={{ width: `${calculatePercentage(item?.rating_count?.value || 0)}%` }}
													aria-valuenow={calculatePercentage(item?.rating_count?.value || 0)}
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
											<div style={{ minWidth: "9%" }}>
												<span>{item?.rating_count?.value}</span>
												{totalStarRatingCount ?
													<span className='ms-1 text-muted' style={{ fontSize: "small" }}> ({((item?.rating_count?.value / totalStarRatingCount) * 100).toFixed(1)}%) </span> : <></>}
											</div>

										</div>
									))}
								</div>
							</Col>
							<Col >
								{(npsRating[0]?.count || npsRating[1]?.count || npsRating[2]?.count) ?
									<Row md={1} lg={2} className='py-3 text-center align-items-center'>
										<Col className="chart-container py-3" >
											<DoughnutChart
												backgroundColor={["#00A76F", "#FEC203", "#E84521"]}
												data={{
													labels: ['Promoters', 'Passives', 'Detractors'],
													datasets: [npsRating[0]?.count, npsRating[1]?.count, npsRating[2]?.count],
												}}
												height={200}
												centerText={{ text: npsAverage ?? "-", subText: 'NPS' }}
												hideLabel={true}
											/>
										</Col>
										<Col className="nps-summary d-flex flex-column justify-content-center"  >
											{npsRating?.map((item) => (
												<div key={item?.label} className="d-flex align-items-center justify-content-center justify-content-lg-start">
													<span
														className="me-2"
														style={{
															width: '12px',
															height: '12px',
															backgroundColor: item?.color,
															borderRadius: '50%',
															display: 'inline-block',
														}}
													></span>
													<span className="me-2">{item?.label}</span>
													<span className="fw-bold me-2">{item?.percentage?.toFixed(1)}%</span>
													<span className="text-muted">({item?.count})</span>
												</div>
											))}
										</Col>
									</Row> :
									<div className='d-flex align-items-center justify-content-center h-100 text-muted text-sm'>No NPS data found</div>
								}
							</Col>
						</Row>
					</Spinner>
				</Card>
				<Row>
					<Col md={12} lg={8}>
						{shipperGroup ?
							<Card>
								<CardHeader>
									Locations
								</CardHeader>
								<Spinner display={spinner}>
									<Table responsive size='sm' className='mb-0' >
										<thead>
											<tr>
												<th className='text-left cursor-pointer' data-sort="tables-row" onClick={() => { onSort("shipper_name.hits.hits.0._source.shipper.name", locationData, setLocationData) }}>Locations</th>
												<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_rating.value", locationData, setLocationData) }}>Rating</th>
												<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_nps.value", locationData, setLocationData) }}>Nps</th>
												<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_response_rate", locationData, setLocationData) }}>Response Rate</th>
											</tr>
										</thead>
										<tbody>
											{locationData?.map((rating) => {
												return (
													<>
														< tr key={rating?.key}>
															<td className='text-left'>{rating?.shipper_name?.hits?.hits[0]?._source?.shipper?.name}</td>
															<td className='text-center'>{rating?.avg_rating?.value ? <><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</> : "-"}</td>
															<td className='text-center'>{rating?.avg_nps?.value}</td>
															<td className='text-center'>{rating?.avg_response_rate ? `${rating?.avg_response_rate}%` : "-"}</td>
														</tr >

													</>
												)
											})
											}
										</tbody>
									</Table>
								</Spinner>
							</Card> :
							<Card>
								<CardHeader>
									Clients
								</CardHeader>
								<Spinner display={spinner}>
									<Table responsive size='sm' className='mb-0'>
										<thead>
											<tr>
												<th className='text-left cursor-pointer' data-sort="tables-row" onClick={() => { onSort("client_name.hits.hits.0._source.shipperGroup.name", clientData, setClientData) }}> Client</th>
												<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_rating.value", clientData, setClientData) }}>Rating</th>
												<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_nps.value", clientData, setClientData) }}>Nps</th>
												<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_response_rate", clientData, setClientData) }}>Response Rate</th>
											</tr>
										</thead>
										<tbody>
											{clientData?.map((rating) => {
												return (
													<>
														< tr key={rating?.key}>
															<td className='text-left'>{rating?.client_name?.hits?.hits[0]?._source?.shipperGroup?.name}</td>
															<td className='text-center'>{rating?.avg_rating?.value ? <><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</> : "-"}</td>
															<td className='text-center'>{rating?.avg_nps?.value}</td>
															<td className='text-center'>{rating?.avg_response_rate ? `${rating?.avg_response_rate}%` : "-"}</td>
														</tr >

													</>
												)
											})
											}
										</tbody>
									</Table>
								</Spinner>
							</Card>}
					</Col>
					<Col md={12} lg={4}>
						<Card>
							<CardHeader>
								Courier
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-start cursor-pointer' data-sort="tables-row" onClick={() => { onSort("key", courierData, setCourierData) }}>Courier</th>
											<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { onSort("avg_rating.value", courierData, setCourierData) }}>Rating</th>
										</tr>
									</thead>
									<tbody>
										{
											courierData?.map((rating, index) => {
												return (
													< tr key={rating?.key} >
														<td className='text-start'>{rating?.key}</td>
														<td className='text-center'>{rating?.avg_rating?.value ? (<><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</>) : "-"}</td>
													</tr >
												)
											})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
				</Row>

				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-start'>Shipment</th>
									<th className='text-start'>Reviews</th>
									<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { handleElasticSort("ratings.items.rating") }}>Rating</th>
									<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { handleElasticSort("ratings.items.nps") }}>NPS</th>
									<th className='text-center cursor-pointer' data-sort="tables-row" onClick={() => { handleElasticSort("ratings.items.createdAt") }}>Date</th>
								</tr>
							</thead>
							<tbody>
								{
									ratings?.length ? (ratings?.map((rating) => {
										return (
											(rating?._source?.ratings?.items[0]?.feedback || rating?._source?.ratings?.items[0]?.nps || rating?._source?.ratings?.items[0]?.rating) &&
											< tr key={rating?._source?.number}>
												<td style={{ wordWrap: "break-word", maxWidth: "80px" }}>
													<div className="text-muted">#{rating?._source?.number}</div>
													{rating?._source.shipper?.name}
													<div className='small text-muted'>
														{rating?._source?.shipperGroup?.name}
													</div>
												</td>
												<td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
													{rating?._source?.ratings?.items[0]?.feedback || '-'}
													<div className='text-small text-muted text-wrap'>{rating?._source?.shipTo?.name}</div>
												</td>
												<td className='text-center'>{rating?._source?.ratings?.items[0]?.rating ? <><span className="text-warning"> &#9733; </span>{rating?._source?.ratings?.items[0]?.rating}</> : "-"}</td>
												<td className='text-center tables-handle'>
													{rating?._source?.ratings?.items[0]?.nps ? <div className={`mx-auto nps nps-${rating?._source?.ratings?.items[0]?.nps * 10}`}></div> : '-'}
												</td>
												<td className='text-center' style={{ maxWidth: "50px" }}>
													{rating?._source?.ratings?.items[0]?.createdAt ?
														<>
															{moment(rating?._source?.ratings?.items[0]?.createdAt).tz(rating?._source.shipFrom?.timezone?.id || "America/New_York").format("h:mm a z")}
															<div className='small text-muted'>{moment(rating?._source?.ratings?.items[0]?.createdAt).tz(rating?._source.shipFrom?.timezone?.id || "America/New_York").format("MMM D, YYYY")}</div>
														</> : "-"}
												</td>
											</tr >

										)
									})) : <tr className="text-muted text-center">
										<td colSpan={5}>No data found</td></tr>
								}
							</tbody>

							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
						</Table>
					</Spinner>
				</Card>
			</Container >
		</>
	);
};

export default RatingDashboard;
