/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://9djzm4gv6h.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4d4jhv4hvja5pbi3rbklto7upu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-s27v2xxq3nhhdj6av6ffi5xluq",
    "aws_cognito_identity_pool_id": "us-east-1:147c8124-1c4a-467e-86a9-ba6d4771acdd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gBdR2L3oZ",
    "aws_user_pools_web_client_id": "2e4uuqvqiv50i4k4iupi7dkn2h",
    "oauth": {
        "domain": "sso-dev.phoxhealth.com.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000,http://localhost:4200,https://hq.dev.phoxhealth.com,https://my.dev.phoxhealth.com,https://trax.dev.phoxhealth.com,https://trax.v5.phoxhealth.com,trax://",
        "redirectSignOut": "http://localhost:3000/auth/logout,http://localhost:4200/auth/logout,https://hq.dev.phoxhealth.com/auth/logout,https://my.dev.phoxhealth.com/auth/logout,https://trax.dev.phoxhealth.com/auth/logout,https://trax.v5.phoxhealth.com/auth/logout,trax://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "phoxhealth-dfc14175fa4a9-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "here-dev": {
                        "style": "VectorHereExplore"
                    },
                    "light-dev": {
                        "style": "VectorOpenDataStandardLight"
                    },
                    "monochrome-dev": {
                        "style": "VectorEsriLightGrayCanvas"
                    },
                    "satellite-dev": {
                        "style": "HybridHereExploreSatellite"
                    }
                },
                "default": "light-dev"
            }
        }
    }
};


export default awsmobile;
